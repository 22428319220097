import React  from 'react';
import './App.css';
import './Camera.css';
import { Tooltip } from 'react-tooltip';
import CameraListToolbar from './CameraListToolbar.js';

import { useNavigate } from "react-router-dom";

class CameraList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			cameraListJsonObj: null,
		};

		this.serverGetCameraList = this.serverGetCameraList.bind(this);		
		this.handleSelectCamera = this.handleSelectCamera.bind(this);
		this.renderOneCamera = this.renderOneCamera.bind(this);
		this.renderAllCameras = this.renderAllCameras.bind(this);
	}

	componentDidMount() {
		var propertyID = this.props.propertyID;
		this.serverGetCameraList(propertyID);
	}
	
	async serverGetCameraList(propertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getcameralist?propertyid=" + propertyID;

		var res = await fetch(url);
		var cameraListJsonObj = await res.json();

		this.setState({cameraListJsonObj: cameraListJsonObj});
	}
	
	handleSelectCamera(cameraID) {
		this.props.setSelectedCamera(cameraID);
		const navigate = this.props.navigate;
  		navigate("/cameras/view");
	}
	
	renderOneCamera(itemObj) {

		var cameraID = itemObj.cameraID;
		var cameraName = itemObj.cameraName;
		var lastImageUnixTimeSec = itemObj.lastImageUnixTimeSec;
		
		var bucketName = this.props.getCameraBucketName(cameraID, lastImageUnixTimeSec);
		var filename = lastImageUnixTimeSec + ".jpg";
		
		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var imageURL = storageBaseURLAPI + "/getimagethumbnail?bucketname=" + encodeURIComponent(bucketName);
		imageURL = imageURL + "&filename=" + encodeURIComponent(filename);
		imageURL = imageURL + "&width=200&height=200&quality=80";

		return (
			<div key={cameraID} className="CameraListOneCamera" onClick={() => this.handleSelectCamera(cameraID)}>
				<img src={imageURL} />
				<br/>
				{cameraName}
			</div>
		);
	}
	
	renderAllCameras() {
		
		var cameraListJsonObj = this.state.cameraListJsonObj;
		if (cameraListJsonObj === null) {
			return;
		}
		
		if (cameraListJsonObj.length === 0) {
			return (<div>No cameras found</div>);
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<cameraListJsonObj.length; i++) {
			itemObj = cameraListJsonObj[i];
			jsxCode = this.renderOneCamera(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	render() {

		return (
			<div className="CameraListTopContainer">
				<Tooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<CameraListToolbar
					qaAppUserType={this.props.dashboardAppUserType}
					propertyID={this.props.propertyID}
					propertyName={this.props.propertyName}
					tab={this.state.tab}
					setTab={this.setTab}
					getLang={this.props.getLang}
				/>
				<div style={{height: "10px"}}></div>
				{this.renderAllCameras()}
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function CameraListFunction(props) {
	const navigate = useNavigate();
	return <CameraList {...props} navigate={navigate} />;
}


