import React  from 'react';
import moment from 'moment';
import './App.css';
import './Table.css';

import { useNavigate } from "react-router-dom";

class TableViewOneImage extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			limJsonObj: null,
			imageWidth: 0,
			imageHeight: 0,
		};

		this.serverGetLIMFile = this.serverGetLIMFile.bind(this);
		this.onImgLoad = this.onImgLoad.bind(this);
		this.renderOneLabelSVG = this.renderOneLabelSVG.bind(this);
		this.renderAllLabels = this.renderAllLabels.bind(this);
		this.renderImage = this.renderImage.bind(this);		
	}

	componentDidMount() {
		var tableID = this.props.tableID;
		this.serverGetLIMFile(tableID);
	}
	
	async serverGetLIMFile(tableID) {

		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var tableID = this.props.tableID;
		var imageUnixTimeSec = this.props.imageUnixTimeSec;
		var bucketName = this.props.getTableBucketName(tableID, imageUnixTimeSec);
		var filename = imageUnixTimeSec + ".lim";

		var url = storageBaseURLAPI + "/" + bucketName + "/" + filename;

		var limJsonObj = null;
		try {
			var res = await fetch(url);
			limJsonObj = await res.json();
		} catch (err) {
		}
		
		this.setState({
			limJsonObj: limJsonObj,
		});
	}
	
	onImgLoad({target:img}) {

		var imageWidth = img.offsetWidth;
        var imageHeight = img.offsetHeight
		
		this.setState({
			imageWidth: imageWidth,
			imageHeight: imageHeight,
		});
    }

	renderOneLabelSVG(index, itemObj) {

		var imageWidth = this.state.imageWidth;
		var imageHeight = this.state.imageHeight;
		var label = itemObj.label;
		var x = itemObj.x;
		var y = itemObj.y;
		var w = itemObj.w;
		var h = itemObj.h;
		
		var stroke = "black";		
		if (label === "coffee cup") {
			var stroke = "blue";
		}
		if (label === "beer bottle") {
			var stroke = "red";
		}
		
		var rectX = imageWidth * (x - w/2);
		var rectY = imageHeight * (y - h/2);
		var rectW = imageWidth * w;
		var rectH = imageHeight * h;
		
		var style = {};
		style.fill = "black";
		style.fillOpacity = 0.0;
		style.stroke = stroke;
		style.strokeWidth = 1;
		style.strokeOpacity = 1.0;
		
		return (
			<rect key={index} x={rectX} y={rectY} width={rectW} height={rectH} style={style} />
		);
	}

	renderAllLabels(limJsonObj) {

		if (limJsonObj === null) {
			return;
		}
		
		var imageWidth = this.state.imageWidth;
		var imageHeight = this.state.imageHeight;
		if ((imageWidth === 0) ||(imageHeight === 0)) {
			return;
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<limJsonObj.length; i++) {
			itemObj = limJsonObj[i];
			jsxCode = this.renderOneLabelSVG(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<svg width={imageWidth} height={imageHeight}>
				{jsxArray}
			</svg>
		);
	}

	renderImage() {

		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var tableID = this.props.tableID;
		var imageUnixTimeSec = this.props.imageUnixTimeSec;
		var bucketName = this.props.getTableBucketName(tableID, imageUnixTimeSec);
		var filename = imageUnixTimeSec + ".jpg";
		var imageURL = storageBaseURLAPI + "/" + bucketName + "/" + filename;
		
		var limJsonObj = this.state.limJsonObj;
		var jsxCodeSVG = this.renderAllLabels(limJsonObj);

		var i;
		var itemObj;
		var label;
		var sublabel;
		var score;
		var score2;
		var sublabelString;
		var labelString = "";

		if (limJsonObj !== null) {
			for(i=0; i<limJsonObj.length; i++) {
				itemObj = limJsonObj[i];
				label = itemObj.label;
				sublabel = itemObj.sublabel;
				if (typeof sublabel === "undefined") {
					sublabel = "";
				}
				score = itemObj.score;
				score2 = Math.floor(100*score) / 100;
				if (i > 0) {
					labelString = labelString + " - ";
				}
				if (sublabel === "") {
					sublabelString = "";
				} else {
					sublabelString = "/" + sublabel;
				}
				labelString = labelString + label + sublabelString + " " + score2;
			}
		}
		
		var dateObj = new Date(imageUnixTimeSec * 1000);
		var dateTimeString = moment(dateObj).format('DD.MM.YYYY HH:mm:ss');
		
		return (
			<div>
				<div className="TableViewOneImageContainer">
					<img src={imageURL} onLoad={this.onImgLoad} />
					{jsxCodeSVG}
				</div>
				<div>{dateTimeString}</div>
				<div>{labelString}</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}

	render() {
		
		var cameraImageListJsonObj = this.state.cameraImageListJsonObj;
		if (cameraImageListJsonObj === null) {
			return;
		}

		return this.renderImage();
	}
}

export default function TableViewOneImageFunction(props) {
	const navigate = useNavigate();
	return <TableViewOneImage {...props} navigate={navigate} />;
}


