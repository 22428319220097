import React  from 'react';
import './App.css';
import './DashboardMain.css';
import DashboardLang from './DashboardLang.json';
import { Tooltip } from 'react-tooltip';
import DashboardMainToolbar from './DashboardMainToolbar.js';

class DashboardMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			tab: "revenue",
			currentYear: 0,
			currentMonth: 0,
			selectedYear: 0,
			selectedMonth: 0,
			daysInMonth: 0,
			dashboardVariableListJsonObj: null,
			compareMonthTotalRoomRevenue: 0,
			compareMonthTotalOtherRevenue: 0,
			dashboardID: 0,
		};
	}

	componentDidMount() {
	}
	
	componentDidUpdate() {
	}

	render() {

		return (
			<div className="DashboardMainTopContainer">
				<Tooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<DashboardMainToolbar
					qaAppUserType={this.props.dashboardAppUserType}
					propertyID={this.props.propertyID}
					propertyName={this.props.propertyName}
					tab={this.state.tab}
					setTab={this.setTab}
					getLang={this.props.getLang}
				/>
				<div style={{height: "10px"}}></div>
				<div>Dashboard</div>
				<div style={{height: "10px"}}></div>
			</div>
		);
	}
}

export default DashboardMain;

