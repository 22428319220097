import React  from 'react';
import './App.css';
import './SelectProperty.css';

import { useNavigate } from "react-router-dom";

class SelectProperty extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			propertyListJsonObj: null,
		};

		this.serverGetPropertyList = this.serverGetPropertyList.bind(this);
		this.handleSelectProperty = this.handleSelectProperty.bind(this);				
		this.renderOneProperty = this.renderOneProperty.bind(this);				
		this.renderAllProperties = this.renderAllProperties.bind(this);				
	}
	
	componentDidMount() {
		this.serverGetPropertyList();
	}
	
	async serverGetPropertyList() {

		var baseURLAPI = this.props.baseURLAPI;
		var companyID = this.props.companyID;
		var url = baseURLAPI + "/getpropertylist?companyid=" + companyID;
		
		const res = await fetch(url);
		const propertyListJsonObj = await res.json();
		
		this.setState({
			propertyListJsonObj: propertyListJsonObj,
		});
	}
	
	handleSelectProperty(propertyID) {
		this.props.setSelectedProperty(propertyID);
		const navigate = this.props.navigate;
  		navigate("/dashboard");
	}

	renderOneProperty(itemObj) {
		var propertyID = itemObj.propertyID;
		var propertyName = itemObj.propertyName;
		return (
			<div key={propertyID} className="SelectPropertyQuestionText" onClick={() => this.handleSelectProperty(propertyID)}>
				{propertyName}
			</div>
		);
	}

	renderAllProperties() {

		var propertyListJsonObj = this.state.propertyListJsonObj;
		if (propertyListJsonObj === null) {
			return;
		}
		
		if (propertyListJsonObj.length === 0) {
			return (<div>No properties found</div>);
		}

		var length = propertyListJsonObj.length;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = propertyListJsonObj[i];
			jsxCode = this.renderOneProperty(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		return (
			<div className="SelectPropertyTopContainer">
				<div style={{height: "20px"}}></div>
				{this.renderAllProperties()}
			</div>
		);
	}
}

export default function SelectPropertyFunction(props) {
	const navigate = useNavigate();
	return <SelectProperty {...props} navigate={navigate} />;
}

