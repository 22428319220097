import React  from 'react';
import './App.css';
import './Camera.css';

import { useNavigate } from "react-router-dom";

class CameraView extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			cameraName: "",
			lastImageUnixTimeSec: 0,
		};

		this.serverGetCameraData = this.serverGetCameraData.bind(this);		
		this.renderOneCamera = this.renderOneCamera.bind(this);
	}

	componentDidMount() {
		var cameraID = this.props.cameraID;
		this.serverGetCameraData(cameraID);
	}
	
	async serverGetCameraData(cameraID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getcameradata?cameraid=" + cameraID;

		var res = await fetch(url);
		var jsonObj = await res.json();
				
		var cameraName = jsonObj.cameraName;
		var lastImageUnixTimeSec = jsonObj.lastImageUnixTimeSec;

		this.setState({
			cameraName: cameraName,
			lastImageUnixTimeSec: lastImageUnixTimeSec,
		});
	}
	
	renderOneCamera() {

		var cameraID = this.props.cameraID;
		var cameraName = this.state.cameraName;
		var lastImageUnixTimeSec = this.state.lastImageUnixTimeSec;
		
		if (lastImageUnixTimeSec === 0) {
			return;
		}
		
		var bucketName = this.props.getCameraBucketName(cameraID, lastImageUnixTimeSec);
		var filename = lastImageUnixTimeSec + ".jpg";
		
		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var imageURL = storageBaseURLAPI + "/" + bucketName + "/" + filename;

		return (
			<div key={cameraID} className="CameraViewOneCameraContainer">
				<img src={imageURL} className="CameraViewOneCameraImage"/>
				<br/>
				{cameraName}
			</div>
		);
	}
	
	render() {

		return (
			<div className="CameraViewTopContainer">
				<div style={{height: "10px"}}></div>
				{this.renderOneCamera()}
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function CameraViewFunction(props) {
	const navigate = useNavigate();
	return <CameraView {...props} navigate={navigate} />;
}


