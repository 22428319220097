import React from 'react';
import './App.css';
import './Navbar.css';
import { AiOutlineLogout } from "react-icons/ai";
import { BsDatabaseCheck, BsCamera } from "react-icons/bs";
import { FaInfo, FaChartLine } from "react-icons/fa";
import { MdOutlineTableBar } from "react-icons/md";

import AppLang from './AppLang.json';

import { useNavigate } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {

		super(props);

		this.handleClick = this.handleClick.bind(this);		
	}
	
	componentDidMount() {
	}

	componentWillUnmount() {
	};
	
	handleClick(page) {

		const navigate = this.props.navigate;

		if (page === "/logout") {
			this.props.logoutClearVariables();
			navigate("/login");
		} else {
			navigate(page);
		}

		this.props.closeNavbar();
	}
	
	render() {
		
		var appUserType = this.props.appUserType;

		/*var showEditDashboardYN = false;
		if (appUserType >= 100) {
			showEditDashboardYN = true;
		}*/

		var navbarOpenYN = this.props.navbarOpenYN;
		if (!navbarOpenYN) {
			return (<div></div>);
		}
		
		return (
			<div id="navbar" className="Navbar">

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/dashboard")}
				>
					<div className="NavbarLinkColumn1">
						<FaChartLine style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "dashboard")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/checkouts")}
				>
					<div className="NavbarLinkColumn1">
						<MdOutlineTableBar style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "checkouts")}</div>
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/cameras")}
				>
					<div className="NavbarLinkColumn1">
						<BsCamera style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "cameras")}</div>
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/selectproperty")}
				>
					<div className="NavbarLinkColumn1">
						<BsDatabaseCheck style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "selectProperty")}</div>
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/about")}
				>
					<div className="NavbarLinkColumn1">
						<FaInfo style={{ color: "white", width: "25px", height: "20px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "about")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/logout")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineLogout style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Logout</div>
				</div>

			</div>
		);
	}
}

export default function NavbarFunction(props) {
	const navigate = useNavigate();
	return <Navbar {...props} navigate={navigate} />;
}

