import React  from 'react';
import './App.css';
import './DashboardMain.css';
import { useNavigate } from "react-router-dom";
import DashboardLang from './DashboardLang.json';

class DashboardMainToolbar extends React.Component {

	constructor(props) {

		super(props);
				
		this.state = {
			innerWidth: 0,
			innerHeight: 0,
			showPopupMenuYN: false,
		}
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.handleSelectDashboard = this.handleSelectDashboard.bind(this);
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.handlePopupLink = this.handlePopupLink.bind(this);
		this.closePopupMenu = this.closePopupMenu.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.renderRight = this.renderRight.bind(this);
	}
	
	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
	};
	
	handleSelectDashboard() {
		const navigate = this.props.navigate;
  		navigate("/selectproperty");
	}
	
	handleSelectLink(tab) {
		this.props.setTab(tab);
	}
	
	handlePopupLink() {
		this.setState({showPopupMenuYN: true});
	}

	closePopupMenu() {
		this.setState({showPopupMenuYN: false});
	}
	
	renderLink(linkTitle, linkValue, key, selectedYN) {

		var classNameString = "DashboardMainToolbarLink";
					
		if (selectedYN) {
			classNameString = "DashboardMainToolbarSelectedLink";
		}
		
		return (
			<div 
				key={key}
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	renderRight() {
		return null;
	}
	
	render() {
		
		var propertyName = this.props.propertyName;
		var dataTip = this.props.getLang(DashboardLang, "selectProperty");
		
		return (
			<div className="DashboardMainToolbarContainer1">
				<div className="DashboardMainToolbarLeft">
					<div 
						className="DashboardMainToolbarLink"
						onClick={() => this.handleSelectDashboard()}
						data-tip={dataTip}
					>
						{propertyName}
					</div>
				</div>
				<div className="DashboardMainToolbarRight">
					<div className="DashboardMainToolbarContainer2">
						{this.renderRight()}
					</div>
				</div>
			</div>
		);
	}
}

export default function DashboardMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <DashboardMainToolbar {...props} navigate={navigate} />;
}

