import React  from 'react';
import './App.css';
import './Table.css';
import TableViewOneImage from './TableViewOneImage.js';

import { useNavigate } from "react-router-dom";

class TableView extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			tableNumber: 0,
			propertyID: 0,
			cameraID: 0,
			index: 0,
			count: 50,
			cameraImageListJsonObj: null,
		};

		this.serverGetTableData = this.serverGetTableData.bind(this);
		this.serverGetCameraImageListForCameraID = this.serverGetCameraImageListForCameraID.bind(this);		
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);	
		this.renderPrevNext = this.renderPrevNext.bind(this);
		this.renderOneImage = this.renderOneImage.bind(this);
		this.renderAllImages = this.renderAllImages.bind(this);
	}

	componentDidMount() {
		var tableID = this.props.tableID;
		this.serverGetTableData(tableID);
	}
	
	async serverGetTableData(tableID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettabledata?tableid=" + tableID;

		var res = await fetch(url);
		var jsonObj = await res.json();
		
		var tableNumber = jsonObj.tableNumber;
		var propertyID = jsonObj.propertyID;
		var cameraID = jsonObj.cameraID;

		var index = this.state.index;
		var count = this.state.count;
		await this.serverGetCameraImageListForCameraID(cameraID, index, count);

		this.setState({
			tableNumber: tableNumber,
			propertyID: propertyID,
			cameraID: cameraID,
		});		
	}
	
	async serverGetCameraImageListForCameraID(cameraID, index, count) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getcameraimagelistforcameraid?cameraid=" + cameraID;
		url = url + "&index=" + index;
		url = url + "&count=" + count;

		var res = await fetch(url);
		var cameraImageListJsonObj = await res.json();

		this.setState({
			cameraImageListJsonObj: cameraImageListJsonObj,
		});		
	}
	
	handlePrev() {
		var index = this.state.index - this.state.count;
		this.setState({index: index});
		this.serverGetCameraImageListForCameraID(this.state.cameraID, index, this.state.count);
	}

	handleNext() {
		var index = this.state.index + this.state.count;
		this.setState({index: index});
		this.serverGetCameraImageListForCameraID(this.state.cameraID, index, this.state.count);
	}
	

	renderPrevNext(prevYN, nextYN) {
		
		if ((!prevYN) && (!nextYN)) {
			return null;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}
		
	renderOneImage(itemObj) {
		
		var cameraImageID = itemObj.cameraImageID;
		var imageUnixTimeSec = itemObj.imageUnixTimeSec;

		return (
			<div key={cameraImageID}>
				<TableViewOneImage
					storageBaseURLAPI={this.props.storageBaseURLAPI}
					tableID={this.props.tableID}
					imageUnixTimeSec={imageUnixTimeSec}
					getTableBucketName={this.props.getTableBucketName}
					getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				/>
			</div>
		);
	}

	renderAllImages() {

		var cameraImageListJsonObj = this.state.cameraImageListJsonObj;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<cameraImageListJsonObj.length; i++) {
			itemObj = cameraImageListJsonObj[i];
			jsxCode = this.renderOneImage(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	render() {
		
		var cameraImageListJsonObj = this.state.cameraImageListJsonObj;
		if (cameraImageListJsonObj === null) {
			return;
		}
		
		var tableNumber = this.state.tableNumber;
		var prevYN = false; 
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (cameraImageListJsonObj.length <= this.state.count) {
			nextYN = true;
		}
		
		return (
			<div className="TableViewTopContainer">
				<div style={{height: "10px"}}></div>
				<div className="TableViewTableNumber">Table {tableNumber}</div>
				<div style={{height: "10px"}}></div>
				{this.renderPrevNext(prevYN, nextYN)}
				<div style={{height: "10px"}}></div>
				{this.renderAllImages()}
				<div style={{height: "10px"}}></div>
				{this.renderPrevNext(prevYN, nextYN)}
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function TableViewFunction(props) {
	const navigate = useNavigate();
	return <TableView {...props} navigate={navigate} />;
}


