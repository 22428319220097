import React  from 'react';
import './App.css';
import './Table.css';
import { Tooltip } from 'react-tooltip';
import TableListToolbar from './TableListToolbar.js';

import { useNavigate } from "react-router-dom";

class TableList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			tableListJsonObj: null,
			cameraImageListJsonObj: null,
		};

		this.serverGetTableList = this.serverGetTableList.bind(this);		
		this.serverGetCameraImageListForPropertyID = this.serverGetCameraImageListForPropertyID.bind(this);		
		this.handleSelectTable = this.handleSelectTable.bind(this);
		this.findImageFromCameraID = this.findImageFromCameraID.bind(this);
		this.renderOneTable = this.renderOneTable.bind(this);
		this.renderAllTables = this.renderAllTables.bind(this);
	}

	componentDidMount() {
		var propertyID = this.props.propertyID;
		this.serverGetTableList(propertyID);
		this.serverGetCameraImageListForPropertyID(propertyID, 0, 100);
	}
	
	async serverGetTableList(propertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettablelist?propertyid=" + propertyID;

		var res = await fetch(url);
		var tableListJsonObj = await res.json();

		this.setState({tableListJsonObj: tableListJsonObj});
	}
	
	async serverGetCameraImageListForPropertyID(propertyID, index, count) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getcameraimagelistforpropertyid?propertyid=" + propertyID;
		url = url + "&index=" + index;
		url = url + "&count=" + count;

		var res = await fetch(url);
		var cameraImageListJsonObj = await res.json();

		this.setState({
			cameraImageListJsonObj: cameraImageListJsonObj,
		});		
	}
	
	handleSelectTable(tableID) {
		this.props.setSelectedTable(tableID);
		const navigate = this.props.navigate;
  		navigate("/tables/view");
	}

	findImageFromCameraID(cameraID1) {

		var cameraImageListJsonObj = this.state.cameraImageListJsonObj;
		var i;
		var itemObj;
		var cameraID2;
		
		for(i=0; i<cameraImageListJsonObj.length; i++) {
			itemObj = cameraImageListJsonObj[i];
			cameraID2 = itemObj.cameraID;
			if (cameraID1 === cameraID2) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	renderOneTable(itemObj) {

		var tableID = itemObj.tableID;
		var tableNumber = itemObj.tableNumber;
		var cameraID = itemObj.cameraID;
		
		var jsonObj = this.findImageFromCameraID(cameraID);

		if (jsonObj === null) {
			return (
				<div key={tableID} className="TableListOneTable" onClick={() => this.handleSelectTable(tableID)}>
					Table {tableNumber}
				</div>
			);
		}
		
		var imageUnixTimeSec = jsonObj.imageUnixTimeSec;
		var bucketName = this.props.getTableBucketName(tableID, imageUnixTimeSec);
		var filename = imageUnixTimeSec + ".jpg";
		
		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var imageURL = storageBaseURLAPI + "/getimagethumbnail?bucketname=" + encodeURIComponent(bucketName);
		imageURL = imageURL + "&filename=" + encodeURIComponent(filename);
		imageURL = imageURL + "&width=200&height=200&quality=80";

		return (
			<div key={tableID} className="TableListOneTable" onClick={() => this.handleSelectTable(tableID)}>
				<img src={imageURL} />
				<br/>
				Table {tableNumber}
			</div>
		);
	}
	
	renderAllTables() {
		
		var tableListJsonObj = this.state.tableListJsonObj;
		if (tableListJsonObj === null) {
			return;
		}
		
		if (tableListJsonObj.length === 0) {
			return (<div>No tables found</div>);
		}
		
		var cameraImageListJsonObj = this.state.cameraImageListJsonObj;
		if (cameraImageListJsonObj === null) {
			return;
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<tableListJsonObj.length; i++) {
			itemObj = tableListJsonObj[i];
			jsxCode = this.renderOneTable(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	render() {

		return (
			<div className="TableListTopContainer">
				<Tooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<TableListToolbar
					qaAppUserType={this.props.dashboardAppUserType}
					propertyID={this.props.propertyID}
					propertyName={this.props.propertyName}
					tab={this.state.tab}
					setTab={this.setTab}
					getLang={this.props.getLang}
				/>
				<div style={{height: "10px"}}></div>
				{this.renderAllTables()}
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function TableListFunction(props) {
	const navigate = useNavigate();
	return <TableList {...props} navigate={navigate} />;
}


