import * as React from 'react';
import './App.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import moment from 'moment';
import {storeEmailPassword} from './LoginLocalStorage.js';
import AppLang from './AppLang.json';

import Topbar from './common/Topbar.js';
import Login from './Login.js';
import DashboardMain from './DashboardMain.js';
import CameraList from './CameraList.js';
import CameraView from './CameraView.js';
import TableList from './TableList.js';
import TableView from './TableView.js';
import SelectProperty from './SelectProperty.js';
import About from './About.js';

class App extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			baseURLAPI: "https://menzaapi.dbtouch.com",
			managementBaseURLAPI: "https://managementapi.plurato.com",
			storageBaseURLAPI: "https://storageapi2.plurato.com:6443",
			dbtouch: 0,
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			fullName: "",
			phone: "",
			companyID: 0,
			language: "en",
			qaAppUserType: 0,
			dashboardUserType: 0,
			firstLoadYN: true,
			navbarOpenYN: false,
			propertyID: 0,
			propertyName: "n/a",
			cameraID: 0,
			tableID: 0,
		}
		
		this.getEURString = this.getEURString.bind(this);
		this.getDayOfWeek = this.getDayOfWeek.bind(this);
		this.getMonthString = this.getMonthString.bind(this);
		this.getMonthStringShort = this.getMonthStringShort.bind(this);
		this.getWeekdayString = this.getWeekdayString.bind(this);
		this.getWeekdayStringShort = this.getWeekdayStringShort.bind(this);
		this.handleMouseClick = this.handleMouseClick.bind(this);
		this.getFilenameAndExtFromFilenameExt = this.getFilenameAndExtFromFilenameExt.bind(this);
		this.serverGetUserDataFromSessionID = this.serverGetUserDataFromSessionID.bind(this);
		this.getSessionIDFromURLString = this.getSessionIDFromURLString.bind(this);
		this.serverUpdateUserLanguage = this.serverUpdateUserLanguage.bind(this);
		this.showToast = this.showToast.bind(this);
		this.logoutClearVariables = this.logoutClearVariables.bind(this);
		this.serverLogout = this.serverLogout.bind(this);
		this.setCompanyName = this.setCompanyName.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.serverDBTouchGetUserData = this.serverDBTouchGetUserData.bind(this);
		this.serverGetUserDBTouchData = this.serverGetUserDBTouchData.bind(this);
		this.setSessionID = this.setSessionID.bind(this);
		this.setUserID = this.setUserID.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.setNavbarOpenYN = this.setNavbarOpenYN.bind(this);
		this.getLang = this.getLang.bind(this);
		this.separateTextIntoArrayOfLines = this.separateTextIntoArrayOfLines.bind(this);
		this.convertTextNewlineToBr = this.convertTextNewlineToBr.bind(this);
		this.getDateStringFromUnixTimeSec = this.getDateStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromUnixTimeSec = this.getTimeStringFromUnixTimeSec.bind(this);
		this.getDateTimeStringFromUnixTimeSec = this.getDateTimeStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
		this.setSelectedProperty = this.setSelectedProperty.bind(this);
		this.setSelectedCamera = this.setSelectedCamera.bind(this);
		this.setSelectedTable = this.setSelectedTable.bind(this);
		this.serverSetUserPropertyID = this.serverSetUserPropertyID.bind(this);
		this.serverGetPropertyData = this.serverGetPropertyData.bind(this);
		this.serverGetUserSettingsData = this.serverGetUserSettingsData.bind(this);
		this.serverGetUserPropertyData = this.serverGetUserPropertyData.bind(this);
		this.getCameraBucketName = this.getCameraBucketName.bind(this);
	}
	
	componentDidMount() {

		document.title = "DBTouch Menze"

		var windowLocation = window.location;
		var windowLocationString = windowLocation.toString();
		var sessionID = this.getSessionIDFromURLString(windowLocationString);
		
		if (sessionID === "") {
			this.setState({firstLoadYN: false});
			return;
		}
		
		this.setState({
			sessionID: sessionID,
		});
		this.serverGetUserDataFromSessionID(sessionID);
	}

	componentWillUnmount() {
	}
	
	getEURString(value, decimalPlaces) {
		value = value.toFixed(decimalPlaces);
		var eurString = new Intl.NumberFormat('de-DE').format(value);
		eurString = eurString + " €";
		return eurString;
	}

	getDayOfWeek(year, month, day) {
		var dateObj = new Date(year, month-1, day, 0, 0, 0, 0);
		var dayOfWeek = dateObj.getDay();
		return dayOfWeek;
	}
	
	getMonthString(month) {
		var monthNames = ["january", "february", "march", "april", "mayFull", "june", "july", "august", "september", "october", "november", "december"];
		var monthString = monthNames[month-1];
		var monthStringLanguage = this.getLang(AppLang, monthString);
		return monthStringLanguage;
	}
	
	getMonthStringShort(month) {
		var monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
		var monthString = monthNames[month-1];
		var monthStringLanguage = this.getLang(AppLang, monthString);
		return monthStringLanguage;
	}
	
	getWeekdayString(weekday) {
		var weekdayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
		var weekdayString = weekdayNames[weekday];
		var weekdayStringLanguage = this.getLang(AppLang, weekdayString);
		return weekdayStringLanguage;
	}
	
	getWeekdayStringShort(weekday) {
		var weekdayNames = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
		var weekdayString = weekdayNames[weekday];
		var weekdayStringLanguage = this.getLang(AppLang, weekdayString);
		return weekdayStringLanguage;
	}
	
	handleMouseClick(e) {

		var target = e.target;
		var id = target.id;
		
		if (id === "fimenu") {
			return;
		}
		
		if (id === "") {
			var parentNode = target.parentNode;
			if (parentNode !== null) {
				id = parentNode.id;
				if (id === "fimenu") {
					return;
				}
				if (id === "") {
					parentNode = parentNode.parentNode;
					if (parentNode !== null) {
						id = parentNode.id;
						if (id === "fimenu") {
							return;
						}
					}
				}
			}
		}

		this.setNavbarOpenYN(false);		
	}

	getFilenameAndExtFromFilenameExt(filenameExt) {
		var length = filenameExt.length;
		var ext = filenameExt.split('.').pop();
		var filename = filenameExt.substring(0, length - ext.length - 1);
		ext = ext.toLowerCase();
		return [filename, ext];
	}
	
	async serverGetUserDataFromSessionID(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdatafromsessionid?sid=" + sessionID;

		try {
			const res = await fetch(url);
			const jsonObj = await res.json();

			var userID = jsonObj.userID;
			var email = jsonObj.email;
			var firstName = jsonObj.firstName;
			var lastName = jsonObj.lastName;
			var phone = jsonObj.phone;
			var language = jsonObj.language;
			var qaAppUserType = 0;
			var companyID = jsonObj.companyID;
			var createdTimestamp = jsonObj.createdTimestamp;

			var fullName = firstName + " " + lastName;
			fullName = fullName.trim();
			if (fullName === "") {
				fullName = email;
			}

			this.setState({
				userID: userID,
				email: email,
				firstName: firstName,
				lastName: lastName,
				fullName: fullName,
				phone: phone,
				language: language,
				qaAppUserType: qaAppUserType,
				companyID: companyID,
				createdTimestamp: createdTimestamp,
				firstLoadYN: false,
			});
		
			this.setCompanyName(companyID);
			this.serverGetUserSettingsData(userID);			
		} catch (e) {
			this.setState({firstLoadYN: false});
		}
	}
	
	getSessionIDFromURLString(urlString) {

		var i;
		var index;
		var length;
		var foundYN;
		var sessionID = "";
		
		length = urlString.length;
		foundYN = false;
		index = 0;
		
		// find sid=
		for(i=0; (i<length) && (!foundYN); i++) {
			if (urlString.substr(i, 4) === "sid=") {
				foundYN = true;
				index = i+4;
			}
		}
		
		if (!foundYN) {
			return "";
		}
		
		sessionID = urlString.substr(index, length-index);
		return sessionID;
	}
	
	async serverUpdateUserLanguage(userID, language) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var dbtouch = this.state.dbtouch;
		var email = this.state.email; 
		var url;

		if (dbtouch) {
			url = managementBaseURLAPI + "/updateuserdbtouchlanguage?email=" + encodeURIComponent(email) + "&language=" + language;
		} else {
			url = managementBaseURLAPI + "/updateuserlanguage?userid=" + userID + "&language=" + language;
		}
		
		const res = await fetch(url);
		await res.text();
	}

	showToast(messageText) {
		toast(messageText, {
			position: "top-center",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	logoutClearVariables() {
		
		var sessionID = this.state.sessionID;
		this.serverLogout(sessionID);

		this.setState({
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			fullName: "",
			phone: "",
			qaAppUserType: 0,
			companyID: 0,
			createdTimestamp: "",
		});
		
		storeEmailPassword("", "");
	}

	async serverLogout(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/logout?sid=" + sessionID;

		const res = await fetch(url);
		await res.text();
	}

	async serverGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var sessionID = this.state.sessionID;
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdata?sid=" + sessionID + "&userid=" + userID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var phone = jsonObj.phone;
		var language = jsonObj.language;
		var qaAppUserType = 0;
		var companyID = jsonObj.companyID;
		var createdTimestamp = jsonObj.createdTimestamp;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		if (fullName === "") {
			fullName = email;
		}

		this.setState({
			email: email,
			firstName: firstName,
			lastName: lastName,
			fullName: fullName,
			phone: phone,
			language: language,
			qaAppUserType: qaAppUserType,
			companyID: companyID,
			createdTimestamp: createdTimestamp,
		});
		
		this.setCompanyName(companyID);
		this.serverGetUserSettingsData(userID);			
	}
	
	async serverDBTouchGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/dbtouchGetUserData?userid=" + userID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var isActive = jsonObj.isActive;
		if (!isActive) {
			this.logoutClearVariables();
			return;
		}

		var email = jsonObj.username;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var companyID = jsonObj.companyId;
		
		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		if (fullName === "") {
			fullName = email;
		}

		this.setState({
			dbtouch: 1,
			email: email,
			firstName: firstName,
			lastName: lastName,
			fullName: fullName,
			companyID: companyID,
		});
		
		this.serverGetUserDBTouchData(email);
		//this.serverDBTouchSetCompanyName(companyID);
		this.serverGetUserSettingsData(userID);
	}
	
	async serverGetUserDBTouchData(email) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdbtouchdata?email=" + encodeURIComponent(email);

		const res = await fetch(url);
		var responseText = await res.text();
		if (responseText === "") {
			return;
		}
		
		var jsonObj = JSON.parse(responseText);

		var language = jsonObj.language;
		var qaAppUserType = jsonObj.qaAppUserType;
		
		if (qaAppUserType < 1) {
			storeEmailPassword("", "");
			this.showToast("You do not have permission to use this app.");
			setTimeout(function () {
				window.location.href = "/login";
			}, 1000);
			return;
		}
		
		this.setState({
			language: language,
			qaAppUserType: qaAppUserType,
		});
}

	async setCompanyName(companyID) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanydata?companyid=" + companyID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var companyName = jsonObj.companyName;
		this.setState({companyName: companyName});
	}
	
	setSessionID(sessionID) {
		this.setState({sessionID: sessionID});
	}
	
	setUserID(userID) {
		this.setState({userID: userID});
	}
	
	setLanguage(language) {
		var userID = this.state.userID;
		this.serverUpdateUserLanguage(userID, language);
		this.setState({language: language});
	}
	
	setNavbarOpenYN(navbarOpenYN) {
		this.setState({
			navbarOpenYN: navbarOpenYN,
		});
	}
	
	getLang(lang, id) {
		var language = this.state.language;
		var id2 = id + "." + language;
		var i;
		var id3;
		var length = lang.length;
		var itemObj;
		var keys;
		var returnString;
		for(i=0; i<length; i++) {
			itemObj = lang[i];
			keys = Object.keys(itemObj);
			id3 = keys[0];
			if (id2 === id3) {
				returnString = itemObj[id2];
				return returnString;
			}
		}
		return "";
	}
	
	separateTextIntoArrayOfLines(inputText) {

		var lineArray = [];

		if (inputText === null) {
			inputText = "";
		}

		if (inputText === "") {
			return lineArray;
		}

		var i;
		var ch;
		var len = inputText.length;
		var oneLineText = "";
		
		for(i=0; i<len; i++) {
			ch = inputText[i];
			if (ch === "\n") {
				lineArray.push(oneLineText);
				oneLineText = "";
			} else {
				if (ch !== "\r") {
					oneLineText = oneLineText + ch;
				}
			}
		}
		
		lineArray.push(oneLineText);

		return lineArray;
	}

	convertTextNewlineToBr(inputText, variable, className) {
		
		var lineArray = this.separateTextIntoArrayOfLines(inputText);
		var jsxArray = [];
		
		var i;
		var len = lineArray.length;
		var oneLineText;
		var key;
		
		for(i=0; i<len; i++) {
			key = variable + "-" + i;
			oneLineText = lineArray[i];
			if (oneLineText === "") {
				jsxArray.push(<div key={key} style={{height: "10px"}}></div>);
			} else {
				jsxArray.push(<div key={key} className={className}>{oneLineText}</div>);
			}
		}
		
		return (<div>{jsxArray}</div>);
	}

	getDateStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var dateString = moment(dateObj).format('DD.MM.YYYY');
		return dateString;
	}

	getTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var timeString = moment(dateObj).format('HH:mm');
		return timeString;
	}

	getDateTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var currentDateObj = new Date();
		var dateObj = new Date(unixTimeSec * 1000);
		
		var year1 = currentDateObj.getFullYear();
		var year2 = dateObj.getFullYear();
		
		var dateTimeString;
		if (year1 === year2) {
			dateTimeString = moment(dateObj).format('DD/MM HH:mm');
		} else {
			dateTimeString = moment(dateObj).format('DD.MM.YYYY HH:mm');
		}

		return dateTimeString;
	}

	getTimeStringFromSeconds(seconds) {
		
		if (seconds === null) {
			return "";
		}

		var secondsLeft = 0;
		var hours = 0;
		var minutes = 0;
		var timeString = "";
		
		secondsLeft = seconds;
		
		hours = Math.floor(secondsLeft / 3600);
		secondsLeft = secondsLeft - hours * 3600;
		
		minutes = Math.floor(secondsLeft / 60);
		secondsLeft = secondsLeft - minutes * 60;
		
		if (hours > 0) {
			timeString = hours + "h ";
		}
		
		if (minutes > 0) {
			timeString = timeString + minutes + "m ";
		}
		
		if ((hours === 0) && (minutes === 0)) {
			timeString = timeString + secondsLeft + "s";
		}

		return timeString;
	}
	
	checkIfLoggedIn() {
				
		var pathname = window.location.pathname;
		
		if (pathname === "/") {
			return;
		}

		var userID = this.state.userID;
		if (userID !== 0) {
			return;
		}
		
		var url = "/";
		window.location.href = url;
	}
	
	setSelectedProperty(propertyID) {
		this.setState({
			propertyID: propertyID,
		});
		this.serverGetPropertyData(propertyID);
		this.serverSetUserPropertyID(this.state.userID, this.state.fullName, this.state.companyID, propertyID);
	}
	
	setSelectedCamera(cameraID) {
		this.setState({
			cameraID: cameraID,
		});
	}
	
	setSelectedTable(tableID) {
		this.setState({
			tableID: tableID,
		});
	}
	
	async serverSetUserPropertyID(userID, fullName, companyID, propertyID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/setuserpropertyid?userid=" + userID;
		url = url + "&userfullname=" + encodeURIComponent(fullName);
		url = url + "&companyid=" + companyID;
		url = url + "&propertyid=" + propertyID;
		
		const res = await fetch(url);
		await res.text();
	}
	
	async serverGetPropertyData(propertyID) {
		
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getpropertydata?propertyid=" + propertyID;
		
		const res = await fetch(url);
		var jsonObj = await res.json();
		var propertyName = jsonObj.propertyName;
		
		this.setState({
			propertyName: propertyName,
		});

		//this.serverGetUserPropertyData(this.state.email, propertyID);
	}
	
	async serverGetUserSettingsData(userID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getusersettingsdata?userid=" + userID;
		
		const res = await fetch(url);
		var responseText = await res.text();
		
		var propertyID = 0;
		if (responseText !== "") {
			var jsonObj = JSON.parse(responseText);
			propertyID = jsonObj.propertyID;
		}
		
		if (propertyID > 0) {
			this.serverGetPropertyData(propertyID);
		}

		this.setState({
			propertyID: propertyID,
		});
	}
	
	async serverGetUserPropertyData(email, propertyID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getuserdashboarddata?email=" + encodeURIComponent(email) + "&propertyid=" + propertyID;
		
		const res = await fetch(url);
		var responseText = await res.text();
		
		var dashboardUserType = 0;
		if (responseText !== "") {
			var jsonObj = JSON.parse(responseText);
			dashboardUserType = jsonObj.userType;
		}
		
		this.setState({
			dashboardUserType: dashboardUserType,
		});
	}
	
	getCameraBucketName(cameraID, unixTimeSec) {
		var dateObj = new Date(unixTimeSec * 1000);
		var dateString = moment(dateObj).format('YYYY-MM-DD');
		var bucketName = "menza-camera-" + cameraID + "-" + dateString;
		return bucketName;
	}
		
	render() {

		this.checkIfLoggedIn();
		
		var firstLoadYN = this.state.firstLoadYN;
		if (firstLoadYN) {
			return null;
		}
		
		var loggedInYN;
		var userID = this.state.userID;
		
		if (userID === 0) {
			loggedInYN = false;
		} else {
			loggedInYN = true;
		}

		var qaAppUserType = this.state.qaAppUserType;
		if (loggedInYN) {
			if (qaAppUserType < 1) {
				return null;
			}
		}

		var dashboardUserType = this.state.dashboardUserType;
		
		return (
		<Router>
			<div className="App" onClick={this.handleMouseClick}>
				<ToastContainer />
				<Topbar 
					userID={this.state.userID}
					email={this.state.email}
					firstName={this.state.firstName}
					lastName={this.state.lastName}
					sessionID={this.state.sessionID}
					appUserType={this.state.qaAppUserType}
					dashboardUserType={this.state.dashboardUserType}
					navbarOpenYN={this.state.navbarOpenYN}
					iconName="MENZA"
					appName="MENZA"
					bkColor1="#4e2675"
					bkColor2="#f0f0f0"
					logoutClearVariables={this.logoutClearVariables}
					setLanguage={this.setLanguage}
					getLang={this.getLang}
					setNavbarOpenYN={this.setNavbarOpenYN}
				/>
        		<Routes>
          			<Route 
						path="/"
						element={
							<div>
							{!loggedInYN && (
								<Login
									managementBaseURLAPI={this.state.managementBaseURLAPI}
									setSessionID={this.setSessionID}
									setUserID={this.setUserID}
									serverGetUserData={this.serverGetUserData}
									serverDBTouchGetUserData={this.serverDBTouchGetUserData}
									getLang={this.getLang}
								/>
							)}
							{loggedInYN && (
								<DashboardMain
									baseURLAPI={this.state.baseURLAPI}
									userID={this.state.userID}
									fullName={this.state.fullName}
									qaAppUserType={this.state.qaAppUserType}
									dashboardUserType={this.state.dashboardUserType}
									propertyID={this.state.propertyID}
									propertyName={this.state.propertyName}
									showToast={this.showToast}
									getLang={this.getLang}
									getMonthString={this.getMonthString}
									getMonthStringShort={this.getMonthStringShort}
									getEURString={this.getEURString}
									getDayOfWeek={this.getDayOfWeek}
									getWeekdayString={this.getWeekdayString}
									getWeekdayStringShort={this.getWeekdayStringShort}
									convertTextNewlineToBr={this.convertTextNewlineToBr}
								/>
							)}
							</div>
						}
					/>
          			<Route 
						path="/login"
						element={
							<Login
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								setSessionID={this.setSessionID}
								setUserID={this.setUserID}
								serverGetUserData={this.serverGetUserData}
								serverDBTouchGetUserData={this.serverDBTouchGetUserData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/dashboard"
						element={
							<DashboardMain
								baseURLAPI={this.state.baseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								qaAppUserType={this.state.qaAppUserType}
								dashboardUserType={this.state.dashboardUserType}
								propertyID={this.state.propertyID}
								propertyName={this.state.propertyName}
								showToast={this.showToast}
								getLang={this.getLang}
								getMonthString={this.getMonthString}
								getMonthStringShort={this.getMonthStringShort}
								getEURString={this.getEURString}
								getDayOfWeek={this.getDayOfWeek}
								getWeekdayString={this.getWeekdayString}
								getWeekdayStringShort={this.getWeekdayStringShort}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
							/>
						}
					/>
         			<Route 
						path="/cameras"
						element={
							<CameraList
								baseURLAPI={this.state.baseURLAPI}
								storageBaseURLAPI={this.state.storageBaseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								qaAppUserType={this.state.qaAppUserType}
								propertyID={this.state.propertyID}
								propertyName={this.state.propertyName}
								showToast={this.showToast}
								getLang={this.getLang}
								getCameraBucketName={this.getCameraBucketName}
								setSelectedCamera={this.setSelectedCamera}
							/>
						}
					/>
         			<Route 
						path="/cameras/view"
						element={
							<CameraView
								baseURLAPI={this.state.baseURLAPI}
								storageBaseURLAPI={this.state.storageBaseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								qaAppUserType={this.state.qaAppUserType}
								cameraID={this.state.cameraID}
								showToast={this.showToast}
								getLang={this.getLang}
								getCameraBucketName={this.getCameraBucketName}
							/>
						}
					/>
         			<Route 
						path="/tables"
						element={
							<TableList
								baseURLAPI={this.state.baseURLAPI}
								storageBaseURLAPI={this.state.storageBaseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								qaAppUserType={this.state.qaAppUserType}
								propertyID={this.state.propertyID}
								propertyName={this.state.propertyName}
								showToast={this.showToast}
								getLang={this.getLang}
								setSelectedTable={this.setSelectedTable}
							/>
						}
					/>
         			<Route 
						path="/tables/view"
						element={
							<TableView
								baseURLAPI={this.state.baseURLAPI}
								storageBaseURLAPI={this.state.storageBaseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								qaAppUserType={this.state.qaAppUserType}
								tableID={this.state.tableID}
								showToast={this.showToast}
								getLang={this.getLang}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
							/>
						}
					/>
         			<Route 
						path="/selectproperty"
						element={
							<SelectProperty
								email={this.state.email}
								qaAppUserType={this.state.qaAppUserType}
								companyID={this.state.companyID}
								baseURLAPI={this.state.baseURLAPI}
								getLang={this.getLang}
								setSelectedProperty={this.setSelectedProperty}
							/>
						}
					/>
          			<Route 
						path="/about"
						element={
							<About
								baseURLAPI={this.state.baseURLAPI}
								getLang={this.getLang}
							/>
						}
          			/>
        		</Routes>
			</div>
    		</Router>
		);
	}
}

export default App;


